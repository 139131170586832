import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>

      <section className="items">
        {window.location.pathname === "/success" && (
          <div className="success">
            <h1>Congratulations, you just bought "3 wise cubes"</h1>
          </div>
        )}
        <ul className="item-list">
          <li>
            <article>
              <img src="https://app-testnet.refinable.com/images/nft-preview.jpg" />
              <h3>3 wise cubes</h3>
              <p>These cubes will change your life</p>
              {window.location.pathname !== "/success" && (
                <a href="https://app-testnet.refinable.com/checkout/629881113215805d4d866488">
                  Buy now
                </a>
              )}
            </article>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default App;
